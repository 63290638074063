import { Button, Form, Input, Spin } from 'antd';
import cx from 'classnames';
import JSEncrypt from 'jsencrypt';
import React, { useState } from 'react';
import { batch } from 'react-redux';
import { Agreement } from '..';
import { getFullVelocityUrl, getMaskMechanism, handleResponse } from '../../../Helper';
import { accountApi } from '../../../api';
import { AssertionOptions, AuthenticatorAssertionRawResponse, LogOnViewModel, LoginResponseStatusCode } from '../../../model/AccountModel';
import { ResponseObjectEntity } from '../../../model/CommonModel';
import { Logger } from '../../../model/LoggingModel';
import { AddSecurityKeyModal, InsertSecurityKeyModal } from '../../common';
import { buildAuthenticatorAssertionResponse, convertToArrayBuffer, handleNavigatorError, isStatusAnError } from '../helpers';
import styles from './logOnForm.module.scss';

const fullVelocityUrl: string = getFullVelocityUrl();
const backgroundImage: string = 'backgroundImage';
const companyName: string = 'Identiv, Inc.';
const usernameInput: string = 'LogOnInfo_UserName';
const passwordInput: string = 'LogOnInfo_Password';

const LogOnForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(false);
	const [keyUserName, setKeyUserName] = useState<string>(undefined);
	const [username, setUsername] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [logOnViewModel, setLogOnViewModel] = useState<LogOnViewModel>(getLogOnViewModel());
	const [showInsertSecurityKey, setShowInsertSecurityKey] = useState<boolean>(false);
	const [isUserNotificationAccepted, setIsUserNotificationAccepted] = useState<boolean>(logOnViewModel.LogOnInfo.ShowUserNotification);

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>, stateFunction: (param: string) => void) => {
		batch(() => {
			setLogOnViewModel({
				...logOnViewModel,
				Message: '',
			});
			const value: string = e.currentTarget.value ? e.currentTarget.value : '';
			stateFunction(value);
		});
	};

	const askForSecurityKey = (response: LogOnViewModel) => {
		accountApi
			.getAssertionOptions(response.LogOnInfo.UserName)
			.then((assertOptions: ResponseObjectEntity<AssertionOptions>) => {
				if (!handleResponse(assertOptions) && assertOptions.Entity.status == 'ok') {
					let makeAssertionOptions: AssertionOptions = assertOptions.Entity;
					makeAssertionOptions.challenge = convertToArrayBuffer(makeAssertionOptions.challenge);
					makeAssertionOptions.allowCredentials = makeAssertionOptions.allowCredentials.map(x => ({
						...x,
						id: convertToArrayBuffer(x.id),
						transports: ['usb'],
					}));

					navigator.credentials
						.get({ publicKey: makeAssertionOptions })
						.then(assertedCredential => {
							const data: AuthenticatorAssertionRawResponse = buildAuthenticatorAssertionResponse(assertedCredential);

							accountApi
								.makeAssertion(data)
								.then(assertion => {
									if (!handleResponse(assertion) && assertion.Entity) {
										response.LogOnInfo.SecurityKeyValidated = true;
										accountApi.logOn(response).then(logOnResponse => {
											batch(() => {
												setIsLoading(false);
												setLogOnViewModel({
													...logOnResponse,
													LogOnInfo: {
														...logOnResponse.LogOnInfo,
														UserName: '',
														Password: '',
													},
												});
												if (logOnResponse.Success) {
													window.location.href = `${location.origin}${logOnResponse.RedirectTo}`;
													setShowInsertSecurityKey(false);
												} else {
													setPassword('');
													if (!logOnViewModel.LogOnInfo.ShowEULAAgreement && !logOnViewModel.LogOnInfo.ShowUserNotification) {
														setLoginButtonDisabled(false);
													}
													handleOnCloseInsertKeyModal();
												}
											});
										});
									} else {
										handleOnCloseInsertKeyModal();
									}
								})
								.catch(err => {
									Logger.writeErrorLog(`${err.name}: ${err.message}`);
									handleOnCloseInsertKeyModal();
								});
						})
						.catch(err => {
							handleNavigatorError(err);
							handleOnCloseInsertKeyModal();
						});
				} else {
					setShowInsertSecurityKey(false);
				}
			})
			.catch(err => {
				Logger.writeErrorLog(`${err.name}: ${err.message}`);
				handleOnCloseInsertKeyModal();
			});
	};

	const handleOnFinishForm = () => {
		batch(() => {
			setIsLoading(true);
			setLoginButtonDisabled(true);
		});

		accountApi
			.getSessionMask()
			.then(responseMask => {
				if (!handleResponse(responseMask)) {
					const encryptEngine: JSEncrypt = getMaskMechanism(responseMask.Entity);
					let keyUserName: string = undefined;
					const model: LogOnViewModel = {
						...logOnViewModel,
						LogOnInfo: {
							...logOnViewModel.LogOnInfo,
							UserName: username,
							Password: encryptEngine.encrypt(password).toString(),
						},
					};
					accountApi.logOn(model).then(response => {
						batch(() => {
							setLogOnViewModel({
								...response,
								LogOnInfo: {
									...response.LogOnInfo,
									UserName: '',
									Password: '',
								},
							});
							if (response.Success) {
								window.location.href = `${location.origin}${response.RedirectTo}`;
							} else if (response.StatusCode === LoginResponseStatusCode.SecurityKeyRequired) {
								setShowInsertSecurityKey(true);
								askForSecurityKey(response);
							} else if (response.StatusCode === LoginResponseStatusCode.AddSecurityKey) {
								keyUserName = response.LogOnInfo.UserName.toUpperCase();
							} else {
								setPassword('');
								if (!logOnViewModel.LogOnInfo.ShowEULAAgreement && !isUserNotificationAccepted) {
									setLoginButtonDisabled(false);
								}
							}
							setKeyUserName(keyUserName);
						});
					});
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const hasError: boolean = isStatusAnError(logOnViewModel.StatusCode);

	const handleOnCloseAddSecurityKeyModal = () => {
		batch(() => {
			setKeyUserName(undefined);
			setLoginButtonDisabled(false);
		});
	};

	const handleOnCloseInsertKeyModal = () => {
		batch(() => {
			setShowInsertSecurityKey(false);
			setLoginButtonDisabled(false);
		});
	};

	return (
		<div className={styles.container}>
			<div className={styles.image} style={{ backgroundImage: `url(${fullVelocityUrl}Content/Images/Login-redgraphic.png?_=${getScriptVersion()})` }} />
			<Spin tip={`${_('Loading')}...`} spinning={isLoading} wrapperClassName={styles.spin} size='large'>
				<div className={styles.loginContainer}>
					<Form name='logOnForm' autoComplete='off' onFinish={handleOnFinishForm}>
						<img
							className={styles.logo}
							alt={companyName}
							title={backgroundImage}
							src={`${fullVelocityUrl}Content/Images/logo-login.png?_=${getScriptVersion()}`}
						/>
						<div className={styles.inputsContainer}>
							{!logOnViewModel.LogOnInfo.IsWindowsAuthenticationEnabled && (
								<>
									<label htmlFor={usernameInput}>
										<strong>{_('OperatorName')}</strong>
									</label>
									<Input
										id={usernameInput}
										aria-label={_('OperatorName')}
										maxLength={50}
										className={styles.input}
										placeholder={_('OperatorName')}
										onChange={e => handleInput(e, setUsername)}
										value={username}
									/>
									<label htmlFor={passwordInput}>
										<strong>{_('Password')}</strong>
									</label>
									<Input.Password
										id={passwordInput}
										aria-label={_('Password')}
										maxLength={50}
										className={cx(styles.input, {
											[styles.error]: hasError,
										})}
										placeholder={_('Password')}
										onChange={e => handleInput(e, setPassword)}
										value={password}
									/>
								</>
							)}
							{logOnViewModel.Message && (
								<label
									className={cx({
										[styles.errorMessage]: hasError,
										[styles.successMessage]: logOnViewModel.StatusCode === LoginResponseStatusCode.LogoutSuccess,
									})}>
									{logOnViewModel.Message}
								</label>
							)}
							<Button
								id={'loginSubmitBtn'}
								className={styles.buttonTypeContainer}
								htmlType='submit'
								type='primary'
								disabled={loginButtonDisabled || isUserNotificationAccepted}>
								{_('Login')}
							</Button>
							{logOnViewModel.Version && (
								<div className={styles.aboutDiv}>
									<label id='aboutDescription'>{_('Version')}: </label>
									<label id={`aboutVersion`}>{logOnViewModel.Version}</label>
								</div>
							)}
						</div>
					</Form>
				</div>
				{showInsertSecurityKey && <InsertSecurityKeyModal handleOnCloseModal={handleOnCloseInsertKeyModal} />}
				{logOnViewModel.LogOnInfo.ShowEULAAgreement && <Agreement logOnViewModel={logOnViewModel} />}
				{isUserNotificationAccepted && <Agreement logOnViewModel={logOnViewModel} setIsUserNotificationAccepted={setIsUserNotificationAccepted} />}
				{keyUserName && (
					<AddSecurityKeyModal
						userName={keyUserName}
						handleOnCloseModal={handleOnCloseAddSecurityKeyModal}
						handleOnSuccess={handleOnCloseAddSecurityKeyModal}
					/>
				)}
			</Spin>
		</div>
	);
};

export { LogOnForm };
