import { Button, notification, Select } from 'antd';
import customProtocolCheck from 'custom-protocol-check';
import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { getFullVelocityUrl, getPageId, handleResponse } from '../../../Helper';
import { enrollmentApi } from '../../../api';
import {
	PersonDetailModel,
	PersonEnrollment,
	PivEnrollment,
	PivProfile,
	PivProfileResponse,
	PivValidation,
	TreeViewPersonGroupModel,
} from '../../../model/EnrollmentModel';
import { Logger } from '../../../model/LoggingModel';
import { UserDefinedFieldModel } from '../../../model/UserDefinedFields';
import { useStoreDispatch, useStoreSelector } from '../../../store';
import {
	setDisplayAddViaSmartCard,
	setFetchingScanData,
	setHavePersonChanged,
	setPersonInformationData,
	setPersonInformationId,
} from '../../../store/enrollment/actions';
import { selectFetchingScanData, selectPersonId, selectSelectedPersonGroup } from '../../../store/enrollment/selectors';
import styles from './pivHeader.module.scss';

const { Option } = Select;

type Props = {
	validationProfileResponse: PivProfileResponse;
	validationResult: PivValidation;
	showCreatePerson: boolean;
	downloadPivReaderUrl: string;
	setUpdatePivPersonData: (newPersonData: PersonDetailModel) => void;
};

//Avoid creating object style inline, since increases reconciliations
const style: React.CSSProperties = { minWidth: 250 };
const validationProfile: string = _('ValidationProfile');
const profilesDropdownId: string = 'enrollmentPivProfilesDropdown';

const PIVHeader: React.FC<Props> = ({ validationProfileResponse, validationResult, showCreatePerson, downloadPivReaderUrl, setUpdatePivPersonData }) => {
	const dispatch = useStoreDispatch();
	const [selectedProfileId, setSelectedProfile] = useState(0);
	const currentPersonGroup: TreeViewPersonGroupModel = useStoreSelector<TreeViewPersonGroupModel>(selectSelectedPersonGroup);
	const personId: number = useStoreSelector<number>(selectPersonId);
	const fetchingScanData: boolean = useStoreSelector<boolean>(selectFetchingScanData);

	useEffect(() => {
		let defaultProfileId: number = 0;
		if (validationResult !== null) {
			defaultProfileId = validationResult.SelectedProfileId;
		} else if (
			validationResult === null &&
			validationProfileResponse !== null &&
			validationProfileResponse.Profiles !== null &&
			validationProfileResponse.Profiles.length > 0
		) {
			defaultProfileId = validationProfileResponse.Profiles[0].Id;
		}

		setSelectedProfile(defaultProfileId);
	}, [validationResult]);

	const handleReadCard = e => {
		const velocityUrl: string = getFullVelocityUrl();
		const readCardInfo: PivEnrollment = {
			PageId: getPageId(),
			VelocityUrl: velocityUrl,
			SelectedProfileId: selectedProfileId,
			ProfilesEnabled: validationProfileResponse.ProfilesEnabled,
			PersonGroupId: currentPersonGroup.GroupId,
			PersonFolderId: currentPersonGroup.FolderId,
			PersonId: personId,
		};

		enrollmentApi
			.getPivEnrollment(readCardInfo)
			.then(response => {
				customProtocolCheck(
					`pivreader://${response}`,
					() => {
						if (confirm(_('VelocityWebPIVDownloadInstructions'))) {
							window.open(downloadPivReaderUrl, '_blank');
						}
					},
					() => {
						dispatch(setFetchingScanData(true));
					},
					1000 * 5,
					() => {
						notification['error']({
							message: 'Unsupported Browser for custom protocol',
						});
					}
				);
			})
			.catch(e => Logger.writeErrorLog(e));
	};

	const handleCreatePerson = (): void => {
		const personEnrollment: PersonEnrollment = {
			PivCardData: validationResult.PivCardData,
			SelectedProfileId: selectedProfileId,
			PersonGroupId: validationResult.PersonGroupId,
			PersonFolderId: validationResult.PersonFolderId,
			PersonId: validationResult.PersonId,
		};

		if (validationResult.PersonId) {
			enrollmentApi
				.updatePersonEnrollment(personEnrollment)
				.then(response => {
					if (!handleResponse(response)) {
						const { CertificatesData, ProfilePictureBase64, LastName, FirstName, Title, Suffix, MiddleName, UserDefinedFields } = response.Entity;
						const personInformation: PersonDetailModel = {
							CertificatesData: CertificatesData,
							ProfilePictureBase64: ProfilePictureBase64,
							LastName: LastName,
							FirstName: FirstName,
							Title: Title,
							Suffix: Suffix,
							MiddleName: MiddleName,
							UserDefinedFields: UserDefinedFields.filter((udf: UserDefinedFieldModel) => udf.IsPIVField),
						};
						batch(() => {
							setUpdatePivPersonData(personInformation);
							dispatch(setDisplayAddViaSmartCard(false));
						});
					}
				})
				.catch(e => Logger.writeErrorLog(e));
		} else {
			enrollmentApi
				.createPersonEnrollment(personEnrollment)
				.then(response => {
					if (!handleResponse(response)) {
						batch(() => {
							dispatch(setPersonInformationData(response.Entity));
							dispatch(setPersonInformationId(-100));
							dispatch(setHavePersonChanged(true));
							dispatch(setDisplayAddViaSmartCard(false));
						});
					}
				})
				.catch(e => Logger.writeErrorLog(e));
		}
	};

	let validationProfilesSelect;
	if (validationProfileResponse !== null) {
		validationProfilesSelect = validationProfileResponse.Profiles.map((pivProfile: PivProfile) => {
			return (
				<Option key={pivProfile.Id} value={pivProfile.Id}>
					{pivProfile.Name}
				</Option>
			);
		});
	}

	const selectDisabled: boolean = fetchingScanData || (validationProfileResponse !== null && !validationProfileResponse.ProfilesEnabled);

	return (
		<div className={styles.container}>
			<div className={styles.optionsContainer}>
				<label htmlFor={profilesDropdownId}>{validationProfile}:</label>
				<Select
					id={profilesDropdownId}
					value={selectedProfileId}
					placeholder={validationProfile}
					style={style}
					onChange={value => setSelectedProfile(value)}
					disabled={selectDisabled}>
					{validationProfileResponse !== null && validationProfileResponse.Profiles.length !== 0 ? validationProfilesSelect : ''}
				</Select>
			</div>
			<Button type='primary' className={styles.readCard} onClick={handleReadCard} disabled={fetchingScanData}>
				{_('ReadCard')}
			</Button>
			{showCreatePerson && (
				<Button type='primary' className={styles.proceedWithEnrollment} onClick={handleCreatePerson}>
					{_('ProceedWithEnrollment')}
				</Button>
			)}
		</div>
	);
};

export { PIVHeader };
